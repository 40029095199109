/**
 * @module Time
 * @description Time Utility
 */
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend( relativeTime );
dayjs.extend( advancedFormat );
dayjs.extend( objectSupport );
dayjs.extend( isBetween );
dayjs.extend( isSameOrAfter );

/**
 * @function getMoment
 * @description Manipulate our data.
 */
export const getMoment = ( date = new Date().toLocaleString( 'en-US', { timeZone: 'America/New_York' } ) ) => dayjs( date );

/**
 * @function getDayOfWeek
 * @description Get numeric day of week
 */
export const getDayOfWeek = () => dayjs().day();

/**
 * @function formateDate
 * @param date - Date object were passing.
 * @param format - How we want the date to be formatted
 * @description Manipulate our data.
 */
export const formatDate = ( format = '', date = new Date() ) => dayjs( date ).format( format );

/**
 * @function parseTimeString
 * @description Split up time string into hour, minutes, seconds
 * @param {string} value the string to split up
 */
export const parseTimeString = ( value ) => {
  const values = value.split( ':' );

  values.forEach( ( val, index ) => {
    values[ index ] = Number( val ) < 10 ? val.charAt( 1 ) : val;
  } );

  return {
    hour: values[ 0 ],
    minute: values[ 1 ],
    second: values[ 2 ]
  };
};
